import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../_services/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent {
  // private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  // username?: string;

  constructor(private tokenStorageService: TokenStorageService, private router: Router) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      // this.roles = user.roles;
      // this.username = user.username;
    }
  }

  ngAfterViewInit(): void {
    // Sélectionne tous les éléments avec la classe .dropdown-submenu > a
    const submenuLinks = document.querySelectorAll('.dropdown-submenu > a');

    // Ajoute un événement click à chaque élément trouvé
    submenuLinks.forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault(); // Empêche le comportement par défaut
        e.stopPropagation(); // Empêche la propagation de l'événement
      });
    });
  }

  logout(): void {
    this.tokenStorageService.signOut();
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }
}
