import { Component } from "@angular/core";
import { MetadataServiceService } from "src/app/_services/metadata-service.service";

@Component({
	selector: "app-pole-autonomie",
	templateUrl: "./personne-situation-handicap.component.html",
	styleUrls: ["./personne-situation-handicap.component.scss"],
})
export class PersonneSituationHandicapComponent {
	infoBandeau = {
		image: "./../../assets/bandeau-site-web-medico-social.jpg",
		titre: "Personnes en situation de handicap",
		breadcrumb: "ACCUEIL > Pôle Autonomie > Personnes en situation de handicap",
	};

	constructor(private metadataService: MetadataServiceService) {}

	ngOnInit(): void {
		this.metadataService.updateMetadata({
			title: "Le Patis Fraux - " + this.infoBandeau.titre,
			description: "Le Patis Fraux - " + this.infoBandeau.titre,
		});
	}
}
