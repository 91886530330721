import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HeaderComponent } from './_structure/header/header.component';
import { FooterComponent } from './_structure/footer/footer.component';
import { MenuComponent } from './_structure/menu/menu.component';
import { HomeComponent } from './home/home.component';
import { CarouselComponent } from './components/carousel/carousel.component';

import { WebserviceService } from './_services/webservice.service';
import { HttpClientModule } from '@angular/common/http';
import { ContactComponent } from './_structure/contact/contact.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialExampleModule } from './materials.module';
import { EtablissementComponent } from './etablissement/etablissement.component';
import { PoleSanitaireComponent } from './pole-sanitaire/pole-sanitaire.component';
import { PoleMedicoSocialComponent } from './pole-medico-social/pole-medico-social.component';
import { PatientsUsagerComponent } from './patients-usager/patients-usager.component';
import { FormationPrestationComponent } from './formation-prestation/formation-prestation.component';
import { ActualitesComponent } from './actualites/actualites.component';
import { NousRejoindreComponent } from './nous-rejoindre/nous-rejoindre.component';
import { NousRejoindreDetailComponent } from './nous-rejoindre-detail/nous-rejoindre-detail.component';
import { ActualitesDetailComponent } from './actualites-detail/actualites-detail.component';
import { PartenaireComponent } from './partenaire/partenaire.component';
import { DonationOngletComponent } from './_structure/donation-onglet/donation-onglet.component';
import { BandeauPageComponent } from './_structure/bandeau-page/bandeau-page.component';
import { HabitatInclusifComponent } from './habitat-inclusif/habitat-inclusif.component';
import { MaisonSportSanteComponent } from './maison-sport-sante/maison-sport-sante.component';
import { ServiceReadaptationComponent } from './pole-medico-social/service-readaptation/service-readaptation.component';
import { ServiceAccueilTemporaireComponent } from './pole-medico-social/service-accueil-temporaire/service-accueil-temporaire.component';
import { FoyerHebergementComponent } from './pole-medico-social/foyer-hebergement/foyer-hebergement.component';
import { ServiceAccompagnementComponent } from './pole-medico-social/service-accompagnement/service-accompagnement.component';
import { SafeHtmlPipe } from './_pipes/safeHtml';
import { ProgrammeRestaurationComponent } from './pole-sanitaire/programme-restauration/programme-restauration.component';
import { ProgrammeReadaptationComponent } from './pole-sanitaire/programme-readaptation/programme-readaptation.component';
import { FaireDonComponent } from './faire-don/faire-don.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { PageConstruiteComponent } from './page-construite/page-construite.component';
import { NousRejoindreSpontaneComponent } from './nous-rejoindre-spontane/nous-rejoindre-spontane.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PolitiqueDeConfidentialiteComponent } from './politique-de-confidentialite/politique-de-confidentialite.component';
import {GoogleMapsModule} from "@angular/google-maps";
import { PersonneAgeeComponent } from './pole-sanitaire/personne-agees/personne-agees.component';
import { PersonneSituationHandicapComponent } from './pole-sanitaire/personne-situation-handicap/personne-situation-handicap.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    HomeComponent,
    CarouselComponent,
    ContactComponent,
    EtablissementComponent,
    PoleSanitaireComponent,
    PoleMedicoSocialComponent,
    PatientsUsagerComponent,
    FormationPrestationComponent,
    ActualitesComponent,
    NousRejoindreComponent,
    NousRejoindreDetailComponent,
    ActualitesDetailComponent,
    PartenaireComponent,
    DonationOngletComponent,
    BandeauPageComponent,
    HabitatInclusifComponent,
    MaisonSportSanteComponent,
    ServiceReadaptationComponent,
    ServiceAccueilTemporaireComponent,
    FoyerHebergementComponent,
    ServiceAccompagnementComponent,
    SafeHtmlPipe,
    ProgrammeRestaurationComponent,
    ProgrammeReadaptationComponent,
    FaireDonComponent,
    MentionsLegalesComponent,
    PageConstruiteComponent,
    NousRejoindreSpontaneComponent,
    PolitiqueDeConfidentialiteComponent,
    PersonneAgeeComponent,
    PersonneSituationHandicapComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MaterialExampleModule,
    MatDialogModule,
    GoogleMapsModule,
  ],
  providers: [
    WebserviceService,
    authInterceptorProviders
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
