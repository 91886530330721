<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image"
    [titreBandeau]="infoBandeau.titre"></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="sanitaire__section">
    <div class="container-fuid g-0">
        <div class="row row-sanitaire bg-gray d-flex justify-content-xl-start justify-content-center pb-5">
            <h2 class="page-title">Pôle Autonomie : Personnes âgées</h2>
            <div class="col-xxl-6 col-xl-5 col-md-10 d-flex justify-content-start">
                <img class="bg-grey col-11" src="./../../assets/le-clos-orriere.jpg">
            </div>
            <div class="col-xxl-4 col-xl-6 col-md-10 col-11">
                <div class="title-with-pill mb-4">
                    <span class="pill-title"></span>
                    <h2 class="texte">EHPAD du Clos d’Orrière</h2>
                </div>
                <p>
                    L’EHPAD du Clos d’Orrière accueille 92 résidents en hébergement permanent dont
                    20 en unité de vie protégée et propose 6 places en accueil de jour dont peuvent
                    bénéficier des personnes présentant des troubles cognitifs.
                </p>
                <p>
                    Les professionnels de l’établissement accompagnent des personnes âgées dépendantes
                    en leur garantissant un projet de vie et de soins adapté aux besoins et attentes de chaque résident.
                </p>
                <div class="d-flex justify-content-end">
                    <a class="btn btn-grey" [routerLink]="['/pole-medico-social/ehpad-clos-orriere']">En savoir plus</a>
                </div>
            </div>
        </div>
    </div>
</section>