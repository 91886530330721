<footer>
  <div class="container-fluid container-footer">
    <div class="row g-0">
      <div class="col-md-2 img-footer">
        <img src="../../../assets/Logo-Le-Patis-Fraux-black.png" class="img-fluid">
      </div>
      <div class="col-md-2 d-flex align-items-start">
        <span class="information__span">EHPAD Le Clos d'Orrière</span>
        <span>Téléphone<br/><span><a href="tel:+33299627548">02 99 62 75 48</a></span></span>
        <span>Adresse<br/><span>1, rue Stéphane Hessel 35770 VERN SUR SEICHE</span></span>
		    <span>Horaires de l’accueil<br/>
          <span>Du lundi au vendredi de 9h00 à 12h30 et de 14h00 à 18h00</span><br/>
          <span>Le samedi de 9h00 à 12h00</span>
        </span>
      </div>
      <div class="col-md-2 d-flex align-items-start">
        <span class="information__span">L'Association Le Patis Fraux</span>
        <span>Téléphone<br/><span><a href="tel:+33299048383">02 99 04 83 83</a></span></span>
        <span>Adresse<br/><span>2, allée Salvador Dali 35770 VERN SUR SEICHE</span></span>
		    <span>Horaires de l’accueil<br/><span>Du lundi au vendredi de 9h00 à 12h30 et de 13h30 à 17h00</span></span>
      </div>
      <div class="col-md-2 col-responsive-first">
        <span>Navigation</span>
        <a routerLink="/" routerLinkActive="active">Accueil</a>
        <a routerLink="/etablissement" routerLinkActive="active">Etablissement</a>
        <a routerLink="/patient-usager" routerLinkActive="active">Vous êtes patient ou usager</a>
        <a routerLink="/formation-prestation" routerLinkActive="active">Formations</a>
      </div>
      <div class="col-md-2 col-responsive-second">
        <a routerLink="/actualites" routerLinkActive="active">Actualités</a>
        <a routerLink="/partenaires" routerLinkActive="active" >Partenaires</a>
        <a routerLink="/nous-rejoindre" routerLinkActive="active">Nous rejoindre</a>
        <a routerLink="/contactez-nous" routerLinkActive="active">Contactez-nous</a>
        <a href="#">Plan du site</a>
      </div>
    </div>
  </div>
  <div class="text-bottom">
    <span> <a [routerLink]="['/politique-de-confidentialite']">Politique de confidentialité |</a> <a [routerLink]="['/mentions-legales']" > Mentions Légales |</a> <a href="#"> Agence 11h10</a></span>
    <div class="reseau-container mt-4">
      <a href="https://www.linkedin.com/company/centre-de-r%C3%A9adaptation-du-patis-fraux/mycompany/?viewAsMember=true" target="_blank">
        <i class="fab fa-linkedin-in linkedin"></i>
      </a>
      <a href="https://www.instagram.com/pfraux/" target="_blank">
        <i class="fab fa-instagram instagram"></i>
      </a>
    </div>
  </div>
</footer>
