<app-bandeau-page [breadcrumb]="infoBandeau.breadcrumb" [linkBandeau]="infoBandeau.image" [titreBandeau]="infoBandeau.titre" ></app-bandeau-page>
<app-donation-onglet></app-donation-onglet>
<section class="sanitaire__section pb-5">
  <div class="container-fuid g-0">
    <div class="row row-sanitaire bg-gray d-flex justify-content-xl-start justify-content-center pb-5">
      <h2 class="page-title">Pôle Rééducation / Réadaptation</h2>
      <div class="col-xxl-6 col-xl-7 col-md-10">
        <img class="bg-grey col-11" src="./../../assets/rfr1.jpg">
      </div>
      <div class="col-xxl-4 col-xl-5 col-md-10 col-11">
        <div class="title-with-pill align-items-xl-start">
          <span class="pill-title"></span>
          <h2 class="texte">Service de soins médicaux et de réadaptation : Programme de Restauration Fonctionnelle du Rachis</h2>
        </div>
        <li class="sub-title">Un réentrainement progressif à l’effort et du renforcement musculaire.</li>
        <p>
          Le programme de Restauration Fonctionnelle du Rachis est un traitement actif des
          lombalgies chroniques réalisé par une équipe pluridisciplinaire qui s’adresse aux patients
          invalidés par leurs problèmes de dos et/ou ayant un enjeu de reprise d’une activité
          professionnelle.
        </p>
        <div class="d-flex justify-content-end">
          <a class="btn btn-grey" [routerLink]="['/pole-sanitaire/programme-restauration']">En savoir plus</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center mt-5">
    <div class="col-xxl-5 col-xl-4 col-md-10 col-11">
      <div class="title-with-pill">
        <span class="pill-title"></span>
        <h2 class="texte">Service de soins médicaux et de réadaptation : <br/> Programme de Réhabilitation professionnelle</h2>
      </div>
      <p>
        Le Service de Soins Médicaux et de Réadaptation prend en charge des patients
        nécessitant des soins de rééducation et de réadaptation après une maladie, un
        accident ou une intervention chirurgicale avec un enjeu de reprise d’activité
        professionnelle.
      </p>
      <div class="d-flex justify-content-end">
        <a class="btn btn-grey" [routerLink]="['/pole-sanitaire/programme-readaptation']">En savoir plus</a>
      </div>
    </div>
    <div class="col-xxl-5 col-xl-7 col-md-10">
      <img class="bg-grey col-11" src="./../../assets/rehabilitation-pro.jpg">
    </div>
  </div>
  <div class="row row-page d-flex justify-content-center mt-5">
    <div class="col-xxl-6 col-xl-5 col-md-10 d-flex justify-content-start">
      <img class="bg-grey col-11" src="./../../assets/SRP.jpg">
    </div>
    <div class="col-xxl-4 col-xl-5 col-md-10 col-11">
      <div class="title-with-pill mb-4">
        <span class="pill-title"></span>
        <h2 class="texte">Service de Réadaptation professionnelle</h2>
      </div>
      <p>Le <strong>Service de  Réadaptation Professionnelle</strong> est un établissement médico-social agréé par l’ARS Bretagne pour 40 places, avec une possibilité d’hébergement. Il accueille des personnes reconnues «Travailleur Handicapé» inscrites dans une démarche de reprise d’activité professionnelle compatible avec leur santé et propose un accompagnement global à la réalisation de leur projet.</p>
      <div class="d-flex justify-content-end">
        <a class="btn btn-grey" [routerLink]="['/pole-medico-social/service-readaptation-professionnelle']">En savoir plus</a>
      </div>
    </div>
  </div>
</section>
